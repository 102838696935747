import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { DataCard } from 'modules/ui';
import { DateRange } from 'modules/form';

import * as felaRules from './AverageTrips.styles';
import HorizontalBarChart from '../../HorizontalBarChart';
import { useAverageTrips, useDateRange } from '../../../hooks';
import generateAverageTripsTooltip from '../../../services/utils/generateAverageTripsTooltip';
import { useSelector } from 'react-redux';
import { selectAverageTrips } from 'modules/elevator-detail/services/selectors/floorActivity';

type AverageTripsProps = { isDeactivated?: boolean };

const tripsDemo: any = [
    { bucket: '00-04', value: 2 },
    { bucket: '04-08', value: 5 },
    { bucket: '08-12', value: 7 },
    { bucket: '12-16', value: 9 },
    { bucket: '16-20', value: 10 },
    { bucket: '20-24', value: 2 },
];

const AverageTrips: FC<AverageTripsProps> = ({ isDeactivated }) => {
    const { theme, styles } = useFelaEnhanced(felaRules);
    const { formatTime } = useIntl();

    const { startTime, endTime, handleDateChange } = useDateRange();
    const { inProgress, error } = useAverageTrips({ startTime, endTime });
    const result = useSelector(selectAverageTrips);
    let tripsSummary = result;

    if (isDeactivated) {
        tripsSummary = tripsDemo;
    }

    return (
        <DataCard
            style={{ filter: isDeactivated ? 'blur(1px)' : 'none' }}
            title={
                <Typography.Title level={4} className={styles.cardTitle}>
                    <FormattedMessage id="page.elevatorDetail.tabs.iotData.averageTrips.heading" />
                </Typography.Title>
            }
            subtitle={<DateRange onChange={handleDateChange} />}
            loading={inProgress}
            error={error}
            empty={tripsSummary?.length === 0}
            data-test-id="average-trips"
        >
            <HorizontalBarChart
                data={tripsSummary.map(trip => ({
                    ...trip,
                    tooltipValue: generateAverageTripsTooltip(trip, formatTime),
                }))}
                width={theme.width.chart}
                height={theme.height.chart}
                axisDataKey="bucket"
                customTooltip
                names={{ value: <FormattedMessage id="averageTrips.tooltip.name" /> }}
            />
        </DataCard>
    );
};

export default AverageTrips;
