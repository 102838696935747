import React, { FC, Suspense } from 'react';
import { FormattedDate, FormattedTime, FormattedMessage, useIntl } from 'react-intl';
import { Typography } from 'antd';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';

import { useFelaEnhanced } from 'hooks';

import { CurrentFloor, useElevatorFloorLabel } from 'modules/entities/modules/elevators';

import { useLiveLocation, ElevatorState, TripDirection } from './hooks/useLiveLocation';

import { ElevatorAnimationType } from '../ElevatorAnimation/ElevatorAnimation.types';

import * as felaRules from './LiveLocationContent.rules';

const ElevatorAnimation = React.lazy(() => import('../ElevatorAnimation'));

const LiveLocationContent: FC<{ currentFloor: CurrentFloor; isDeactivated?: boolean }> = ({
    currentFloor,
    isDeactivated,
}) => {
    const [state, callbacks] = useLiveLocation(currentFloor);

    const activeFloorLabel = useElevatorFloorLabel(state?.finalFloor?.floor);
    const activeFloorTime  = state?.finalFloor?.time ? new Date(state?.finalFloor?.time): null;
    const intl = useIntl();
    const { styles } = useFelaEnhanced(felaRules);

    // Adjust number of animation loops to the length of the trip
    const loops = state.tripLength - 1;
    
    return (
        <div className={styles.container}>
            <div className={styles.animationWrapper}>
                <Suspense fallback={null}>
                    {state.elevatorState === ElevatorState.IDLE ? (
                        <ElevatorAnimation type={ElevatorAnimationType.CLOSING_DOOR} autoplay={false} loop={false} />
                    ) : null}
                    {state.elevatorState === ElevatorState.CLOSING_DOOR ? (
                        <ElevatorAnimation
                            type={ElevatorAnimationType.CLOSING_DOOR}
                            autoplay
                            loop={false}
                            onComplete={callbacks.onDoorClosed}
                        />
                    ) : null}
                    {state.elevatorState === ElevatorState.MOVING ? (
                        <ElevatorAnimation
                            type={
                                state.tripDirection === TripDirection.UP
                                    ? ElevatorAnimationType.MOVING_UP
                                    : ElevatorAnimationType.MOVING_DOWN
                            }
                            autoplay
                            loop={
                                // Lottie prop "loop" is badly typed because of LotteOptions interface declaration
                                // The initial animationType if rewriten by React.HTMLProps<HTMLDivElement> which has "loop" too
                                (loops as any) as boolean
                            }
                            onLoopComplete={callbacks.onFloorFinished}
                            onComplete={() => {
                                callbacks.onFloorFinished();
                                callbacks.onTripFinished();
                            }}
                        />
                    ) : null}
                    {state.elevatorState === ElevatorState.OPENING_DOOR ? (
                        <ElevatorAnimation
                            type={ElevatorAnimationType.OPENING_DOOR}
                            autoplay
                            loop={false}
                            onComplete={callbacks.onDoorOpened}
                        />
                    ) : null}
                </Suspense>
            </div>
            {!isDeactivated && (
                <div className={styles.timeWrapper}>
                    <div className={styles.currentFloorWrapper}>
                        <Typography.Text className={styles.floorTitle}>
                            <FormattedMessage id="page.elevatorDetail.tabs.iotData.liveLocation.floor" />
                        </Typography.Text>
                        <Typography.Text className={styles.floorWrapper}>
                            <span className={styles.floor}>{activeFloorLabel}</span>
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Title level={5} className={styles.dateTimeTitle}>
                            <FormattedMessage id="page.elevatorDetail.tabs.iotData.liveLocation.lastTrip" />
                        </Typography.Title>
                        <time dateTime={activeFloorTime && activeFloorTime.toDateString()}>
                            <dl className={styles.dateTimeRow}>
                                <dt>
                                    <ClockCircleOutlined
                                        aria-label={intl.formatMessage({
                                            id: 'page.elevatorDetail.tabs.iotData.liveLocation.time',
                                        })}
                                        className={styles.dateTimeIcon}
                                    />
                                </dt>
                                <dd className={styles.dateTime}>
                                    <FormattedTime
                                        value={activeFloorTime}
                                        hour="numeric"
                                        minute="numeric"
                                        timeZone="Europe/Berlin"
                                    />
                                </dd>
                            </dl>
                            <dl className={styles.dateTimeRow}>
                                <dt>
                                    <CalendarOutlined
                                        aria-label={intl.formatMessage({
                                            id: 'page.elevatorDetail.tabs.iotData.liveLocation.date',
                                        })}
                                        className={styles.dateTimeIcon}
                                    />
                                </dt>
                                <dd className={styles.dateTime}>
                                    <FormattedDate
                                        value={activeFloorTime}
                                        day="numeric"
                                        month="numeric"
                                        year="numeric"
                                        timeZone="Europe/Berlin"
                                    />
                                </dd>
                            </dl>
                        </time>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LiveLocationContent;
